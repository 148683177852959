import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GalleryHolyWeek from "../components/galleryHolyWeek"

const Processions = () => (
  <Layout>
    <SEO title="Processions of the Holy Week and other religious celebrations" />
    <h1>Processions</h1>
    <p>Long time project consisting in portraits and documentary photography around the processions of the Holy Week and other religious celebrations in different places of the Iberian Peninsula.</p>
    <div class="gallery">
    	<GalleryHolyWeek />
    </div>
  </Layout>
)

export default Processions
